<template>
  <div class="chatLogContainer commonScroll" ref="chatLogContainer">
    <div ref="chatContainer">
      <a-spin :spinning="loading">
        <div class="prologue">
          <div class="answerLogo">
            <img :src="logo" />
          </div>
          <div class="answerContent">{{ prologue }}</div>
        </div>
        <!-- <div
          class="loadMore"
          v-if="!logListIsOver && logList.length > 0"
          @click="getChatLog('more')"
        >
          加载更多...
        </div> -->
        <div
          class="chatlogItem"
          v-for="(data, index) in logList"
          :key="data.id + index"
          :class="{ 'scroll-class': data.addClass }"
        >
          <div class="questionArea" v-if="data.textBr">
            <div class="questionLogo">
              <a-avatar :src="userAvatar" :size="32" />
            </div>
            <div class="questionContent">
              <div>
                <a-image
                  class="mb10"
                  v-if="data.image"
                  :width="120"
                  :src="data.image"
                />
                <div v-html="data.textBr"></div>
              </div>
              <img
                src="../../assets/home/edit.png"
                alt=""
                class="edit"
                @click="againSearch(data.text)"
              />
            </div>
          </div>
          <div class="answerArea">
            <div class="answerLogo">
              <img :src="logo" />
              <!-- <div class="aiRoleName">{{ data.aiRole.name }}</div> -->
            </div>
            <div class="answerContent" style="width: calc(100% - 90px)">
              <vue-markdown class="answerContentText">
                {{ data.resultBr }}
              </vue-markdown>
              <img v-for="node in data.resultImages" :key="node" :src="node" />
            </div>
          </div>
          <div class="logTime">{{ data.timeStr }}</div>
        </div>
        <div v-if="showAdd" class="chatlogItem">
          <div class="questionArea">
            <div class="questionLogo">
              <a-avatar :src="userAvatar" :size="32" />
            </div>
            <div class="questionContent">
              <div>
                <a-image
                  class="mb10"
                  :width="120"
                  :src="chatImage"
                  v-if="chatImage"
                />
                <div v-html="chatContent"></div>
              </div>
            </div>
          </div>
          <div class="answerArea" v-if="isLoading">
            <div class="answerLogo">
              <img :src="logo" />
            </div>
            <div class="answerContent">
              <p>您的小助手正在努力工作中哦，请您稍稍等待~~~</p>
              <img
                src="../../assets/home/chat-loading.gif"
                alt=""
                class="gif"
              />
            </div>
          </div>
          <div class="answerArea" v-if="!isLoading">
            <div class="answerLogo">
              <img :src="logo" />
            </div>
            <div class="answerContent" style="width: calc(100% - 90px)">
              <div class="answerContentText" v-html="returnText"></div>
            </div>
          </div>
        </div>
        <span
          :id="`bottom-symbol${index}`"
          style="display: block; height: 1px; width: 1px"
        ></span>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { chatLog, findById } from "@/api/chat";
import moment from "moment";
import robot from "@/assets/home/robot1.png";
import userLogo from "@/assets/home/user_logo.png";
import CryptoJS from "crypto-js";
import VueMarkdown from "vue-markdown";

export default {
  components: {
    VueMarkdown,
  },
  props: {
    // commonParams: {
    //   type: Object,
    //   default: () => {},
    // },
    secret: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 3,
    },
    prologue: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scrollPosition: 0,
      lastId: "",
      logList: [],
      contentToArticle: null,
      logListIsOver: false,
      showAdd: false,
      returnText: "",
      isLoading: false,
      loading: false,
      chatContent: "",
      chatImage: "",
      selectedData: {},
      labelVisible: false,
      tagList: [],
      queryInfo: {},
      isLoadOver: false,
      robot,
      userAvatar: userLogo,
      commonParams: {},
      language: "zh",
      tipCount: 0,
      timer: null,
    };
  },
  mounted() {
    this.initTimer();
  },
  methods: {
    initTimer() {
      clearInterval(this.timer);
      this.tipCount = 0;
      this.timer = setInterval(() => {
        if (this.tipCount < 3) {
          this.tipCount++;
          const data = {
            time: Date.now(),
            timeStr: this.dateTime(Date.now()),
            resultBr:
              "亲，请问还有什么可以帮您的吗？如果您需要进一步了解或演示，可以随时告诉我哦！😊",
          };
          this.logList.push(data);
          setTimeout(() => {
            this.scrollBottom();
          }, 100);
        }
      }, 180000);
    },
    changeLanguage(value) {
      this.language = value;
    },
    dateTime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    updateParams() {
      this.commonParams.timestamp = moment().valueOf();
      this.commonParams.sign = CryptoJS.MD5(
        this.commonParams.dataTemplateId +
          this.commonParams.requesterId +
          this.commonParams.timestamp +
          this.secret
      )
        .toString()
        .toUpperCase();
    },
    setParams(data) {
      this.commonParams = data;
      this.search();
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    search() {
      this.lastId = "";
      if (this.getCookie("hasChatLog")) {
        this.logList = localStorage.getItem("chatLogList")
          ? JSON.parse(localStorage.getItem("chatLogList"))
          : [];
      } else {
        localStorage.setItem("chatLogList", []);
        this.logList = [];
        document.cookie = "hasChatLog=true; path=/";
      }
      // this.logList = [];
      // this.getChatLog("first");
      setTimeout(() => {
        this.scrollBottom();
      }, 100);
    },
    getFirstVisibleChatPosition() {
      const firstChat = this.$refs.chatContainer.offsetHeight; // 跳过加载更多按钮
      console.log(firstChat, "firstChat");
      return firstChat;
    },
    restoreScrollPosition() {
      this.$refs.chatContainer.scrollTop = this.scrollPosition;
    },
    async getChatLog(loadType = "") {
      let firstChatPosition = 0;
      setTimeout(() => {
        firstChatPosition = this.getFirstVisibleChatPosition();
        console.log(firstChatPosition, "firstChatPosition");
      }, 100);
      await this.updateParams();
      this.loading = true;
      chatLog({
        lastId: this.lastId,
        ...this.commonParams,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          if (res.data.length > 0) {
            res.data.forEach((item, index) => {
              item.tags = item.tags || [];
              item.resultImages = item.resultImages || [];
              item.aiRole = item.aiRole ? item.aiRole : {};
              item.result = item.result ? item.result : "";
              item.text = item.text ? item.text : "";
              item.textBr = item.text.replace(/\n/g, "<br>");
              item.resultBr = item.result.replace(/\n/g, "<br>");
              if (index === 0) {
                this.lastId = item.id;
              }
              item.timeStr = this.dateTime(item.time);
            });
            this.logList.forEach((item) => {
              delete item.addClass;
            });
            res.data[0].addClass = 1;
            if (loadType === "first") {
              this.logList = res.data;
            } else {
              this.logList = [...res.data, ...this.logList];
            }
            localStorage.setItem("chatLogList", JSON.stringify(this.logList));
            if (loadType === "more") {
              setTimeout(() => {
                const newFirstChatPosition = this.getFirstVisibleChatPosition();
                const newChatsHeight = newFirstChatPosition - firstChatPosition;
                console.log(newChatsHeight, "newChatsHeight");
                // 调整滚动位置
                this.$refs.chatLogContainer.scrollTop = newChatsHeight;
              }, 100);

              const element = document.querySelector(`.scroll-class`);
              if (element) {
                // element.scrollIntoView({ behavior: 'auto', block: 'start' })
              }
            }
            if (loadType === "first") {
              setTimeout(() => {
                this.scrollBottom();
              }, 100);
            }
            if (res.data.length < 10) {
              this.logListIsOver = true;
            }
          } else {
            this.logListIsOver = true;
          }
        }
      });
    },
    scrollBottom() {
      const element = document.querySelector(`#bottom-symbol${this.index}`);
      if (element) {
        element.scrollIntoView({ behavior: "auto", block: "start" });
      }
    },
    sendLog(data) {
      this.chatContent = data;
      this.chatSearch();
    },
    decodeUTF8(uint8Array) {
      let out = "";
      let i = -1;
      const len = uint8Array.length;

      while (i < len) {
        const charCode = uint8Array[(i += 1)];
        if (charCode < 128) {
          out += String.fromCharCode(charCode);
        } else if (charCode > 191 && charCode < 224 && i < len) {
          out += String.fromCharCode(
            ((charCode & 31) << 6) | (uint8Array[(i += 1)] & 63)
          );
        } else if (charCode > 239 && charCode < 365 && i + 2 < len) {
          const charCode2 = uint8Array[(i += 1)];
          const charCode3 = uint8Array[(i += 1)];
          const charCode4 = uint8Array[(i += 1)];
          const u =
            (((charCode & 7) << 18) |
              ((charCode2 & 63) << 12) |
              ((charCode3 & 63) << 6) |
              (charCode4 & 63)) -
            0x10000;
          out += String.fromCharCode(0xd800 + (u >> 10));
          out += String.fromCharCode(0xdc00 + (u & 1023));
        } else if (charCode > 223 && charCode < 240 && i + 1 < len) {
          out += String.fromCharCode(
            ((charCode & 15) << 12) |
              ((uint8Array[(i += 1)] & 63) << 6) |
              (uint8Array[(i += 1)] & 63)
          );
        } else {
          out += String.fromCharCode(0xfffd);
        }
      }
      return out;
    },
    async chatSearch() {
      clearInterval(this.timer);
      let url = "";
      let gatewayUrl = "";
      if (process.env.NODE_ENV !== "development") {
        if (
          window.location.hostname.indexOf("dev-") !== -1 ||
          window.location.hostname.indexOf("test-") !== -1
        ) {
          //  dev环境
          gatewayUrl = "https://test-plat.cnabke.com";
        } else {
          //  正式环境
          gatewayUrl = "https://plat.cnabke.com";
        }
      } else {
        gatewayUrl = "https://test-plat.cnabke.com";
      }

      url = `${gatewayUrl}/app-ims/ai/webCustomerService/chat`;
      this.isLoadOver = false;
      this.isLoading = true;
      this.showAdd = true;
      this.returnText = "";
      setTimeout(() => {
        this.scrollBottom();
      }, 100);
      await this.updateParams();
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          text: this.chatContent,
          ...this.commonParams,
          // language: this.language,
        }),
        headers: {
          "Content-Type": "application/json",
          // "abke-token": getToken().replace(/"/g, ""), // 直接取出的token是带双引号的，导致报未登录问题，需要转换下
        },
      });
      console.log(response, "response");
      if (response.status === 200) {
        // 获取body的reader
        const reader = response.body.getReader();
        // 初始化一个临时的缓冲区
        let buffer = "";
        // 循环读取reponse中的内容
        while (true) {
          /* eslint-disable no-await-in-loop */
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          // 解码内容
          const text = this.decodeUTF8(value);
          buffer += text;
          while (buffer.includes("{") && buffer.includes("}")) {
            const startIndex = buffer.indexOf("{");
            const endIndex = buffer.indexOf("}") + 1;
            const jsonStr = buffer.substring(startIndex, endIndex);
            buffer = buffer.substring(endIndex);
            // 处理完整的 JSON 对象
            try {
              const obj = JSON.parse(jsonStr);
              if (obj.code && obj.code !== 200) {
                this.$message.warning(obj.message || "请求异常，请稍后再试");
                setTimeout(() => {
                  this.showAdd = false;
                }, 3000);
                break;
              }
              if (obj.id) {
                await this.updateParams();
                findById({
                  chatLogId: obj.id,
                  ...this.commonParams,
                }).then((res) => {
                  const _data = res.data;
                  _data.result = _data.result || "";
                  _data.text = _data.text ? _data.text : "";
                  _data.textBr = _data.text.replace(/\n/g, "<br>");
                  _data.resultBr = _data.result;
                  _data.timeStr = moment(_data.time).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  _data.tags = _data.tags ? _data.tags : [];
                  this.showAdd = false;
                  // if (chatType.value === 'CHAT') {
                  this.logList.push(_data);
                  this.initTimer();
                  localStorage.setItem(
                    "chatLogList",
                    JSON.stringify(this.logList)
                  );
                  // }
                  this.againSearch("");
                  // 有id的，表示结束了
                  this.isLoadOver = true;
                });
                break;
              } else {
                this.isLoading = false;
                obj.text = obj.text.replace(/\n/g, "<br>");
                this.returnText += obj.text;
                setTimeout(() => {
                  this.scrollBottom();
                }, 10);
              }
            } catch (e) {
              if (
                text.indexOf("chatTypeName") > 0 &&
                text.indexOf("companyId") > 0
              ) {
                this.showAdd = false;
                this.isLoadOver = true;
                this.search();
                setTimeout(() => {
                  this.scrollBottom();
                }, 10);
              }
              continue;
            }
          }
        }
      } else {
        this.$message.warning("请求异常，请稍后再试");
        setTimeout(() => {
          this.showAdd = false;
        }, 3000);
      }
    },
    againSearch(text) {
      this.$emit("change", text);
    },
    pushLog(data) {
      console.log(data, "pushLog");
      this.logList.push(data);
      this.initTimer();
      localStorage.setItem("chatLogList", JSON.stringify(this.logList));
      setTimeout(() => {
        this.scrollBottom();
      }, 10);
    },
  },
};
</script>

<style lang="sass" scoped>
.chatLogContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.chatContainer {
  flex: 1;
  padding: 20px;
}
.commonScroll {
  overflow-y: auto;
}
.loadMore {
  color: #1890ff;
  cursor: pointer;
  text-align: center;
}
.chatlogItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.questionArea,
.answerArea {
  display: flex;
  align-items: flex-start;
}
.questionLogo,
.answerLogo {
  margin-right: 10px;
}
.questionContent,
.answerContent {
  flex: 1;
}
.answerContentText {
  // margin-bottom: 10px;
}
.actionArea {
  display: flex;
  justify-content: space-between;
}
.actionBtnItem {
  cursor: pointer;
}
.edit {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.logTime {
  text-align: right;
  color: #999;
}
.gif {
  width: 50px;
  height: 50px;
}
</style>
