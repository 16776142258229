<template>
  <div class="chatInputContainer">
    <div class="chatInput mt10">
      <!-- <a-tabs v-model="tabValue" @change="changeTab" class="noContentTab">
        <a-tab-pane v-for="item in tabList" :key="item" :tab="item">
        </a-tab-pane>
      </a-tabs> -->
      <!-- <a-radio-group
        v-model="languageInfo.value"
        button-style="solid"
        @change="changeLanguage"
        size="small"
        class="languageArea"
      >
        <a-radio-button value="zh">
          中文
        </a-radio-button>
        <a-radio-button value="en">
          英文
        </a-radio-button>
      </a-radio-group> -->
      <div class="chatInputWithUpload">
        <textarea
          class="chatInputTextarea"
          :placeholder="placeholder"
          v-model="sendText"
        />
      </div>
      <div class="sendBtnArea spaceBetweenFlex">
        <span></span>
        <!-- <span class="iconfont icon-audio-fill audioBtn"></span> -->
        <div class="send" @click="sendLog" :class="{ disableSend: !sendText }">
          <span class="iconfont icon-send"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sendText: "",
      sendImage: "",
      sendFile: "",
      placeholder: "请输入您想咨询的问题～",
      tabValue: "文本问答",
      tabList: ["文本问答"],
      queryInfo: {},
      languageInfo: {
        label: "中文",
        value: "zh",
      },
    };
  },
  methods: {
    changeLanguage(data) {
      // this.languageInfo = data;
      this.$emit("changeLanguage", this.languageInfo.value);
    },
    changeForm() {
      this.$emit("changeFilter", this.queryInfo);
    },
    changeTab(e) {
      this.tabValue = e;
      if (e === "文本问答") {
        this.placeholder = "请输入您的问题，问题越清晰全面，回答越精准";
      } else if (e === "图片分析") {
        this.placeholder = "请输入您对图片解析的要求";
      } else if (e === "文档解析") {
        this.placeholder = "请输入解析文档要求";
      }
    },
    getImage(data) {
      this.sendImage = data.length ? data[0].url : "";
    },
    sendLog() {
      if (!this.sendText) {
        return;
      }
      if (this.tabValue === "图片分析") {
        this.$emit("send", this.sendText, this.sendImage);
      } else {
        this.$emit("send", this.sendText);
      }
    },
    setSendText(value) {
      this.sendText = value;
    },
    setSendImage(value) {
      this.sendImage = value;
    },
    changeCollect() {
      this.queryInfo.isCollect = !this.queryInfo.isCollect;
      this.$emit("changeFilter", this.queryInfo);
    },
  },
  mounted() {},
};
</script>

<style lang="sass">
@import url(./index.less);
</style>
