<template>
  <a-affix :offset-top="top" style="z-index: 999" class="mainnav">
    <div class="ctaArea">
      <div class="ctaLabelArea warp">
        <div class="ctaLabel">
          技术创新赋能企业，简化全球贸易复杂性，促进普惠商业繁荣
        </div>
        <div class="ctaLabel">
          智领未来，外贸超级营销员，立即体验
          <a @click="showModal" target="_blank">外贸极客 </a>
          <span
            @click="openAIModal"
            class="iconfont icon-preSalesConsultation"
          ></span>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="nav_top" data-aos="fade">
        <router-link to="/">
          <img
            class="nav-logo"
            referrerpolicy="no-referrer"
            src="~@/assets/AB客.svg"
          />
          <img
            class="nav-logo-white"
            referrerpolicy="no-referrer"
            src="~@/assets/AB客-白.svg"
          />
        </router-link>
        <div class="nav-bar">
          <a-menu v-model="current" mode="horizontal" class="nav-bar-menu">
            <a-menu-item key="home">
              <router-link to="/" rel="noopener noreferrer"> 首页 </router-link>
            </a-menu-item>
            <!-- <a-menu-item key="productService">
              <router-link to="/productService">
                产品服务
              </router-link>
            </a-menu-item> -->
            <a-sub-menu key="product">
              <template #icon>
                <setting-outlined />
              </template>
              <template #title
                >产品服务</template
              >

              <a-menu-item-group title="" class="product-nav">
                <a-menu-item key="AIMarket" class="khxp">
                  <router-link to="/productService"> 智能营销 </router-link>
                </a-menu-item>
                <a-menu-item key="assitant"
                  ><img src="~@/assets/智能助手.png" />
                  <router-link to="/productService"> 智能助手 </router-link>
                </a-menu-item>
                <a-menu-item key="market"
                  ><img src="~@/assets/营销内容.png" />
                  <router-link to="/productService">
                    营销内容
                  </router-link>
                </a-menu-item>
                <a-menu-item key="build"
                  ><img src="~@/assets/建站推广.png" />
                  <router-link to="/productService"> 智能建站 </router-link>
                </a-menu-item>
                <a-menu-item key="clue"
                  ><img src="~@/assets/icon_智能营销.png" />
                  <router-link to="/productService"> 经验策略 </router-link>
                </a-menu-item>
                <a-menu-item key="facebook"
                  ><img src="~@/assets/Facebook.png" />
                  <router-link to="/productService"> 社媒运营 </router-link>
                </a-menu-item>
                <a-menu-item key="content"
                  ><img src="~@/assets/商机监控.png" />
                  <router-link to="/productService"> 内容营销 </router-link>
                </a-menu-item>
              </a-menu-item-group>
              <a-menu-item-group title="" class="product-nav">
                <a-menu-item key="sub-customer-recommend" class="khxp">
                  <router-link to="/product/customer-recommend">
                    客户线索
                  </router-link>
                </a-menu-item>

                <a-menu-item key="customs"
                  ><img src="~@/assets/icon_5.png" />
                  <router-link to="/product/customs"> 海关数据 </router-link>
                </a-menu-item>
                <a-menu-item key="intelligent-search"
                  ><img src="~@/assets/icon_6.png" />
                  <router-link to="/product/intelligent-search">
                    客户智搜
                  </router-link>
                </a-menu-item>
                <a-menu-item key="search-customer"
                  ><img src="~@/assets/icon_7.png" />
                  <router-link to="/product/search-customer">
                    搜邮寻客
                  </router-link>
                </a-menu-item>
                <a-menu-item key="customer-leads"
                  ><img src="~@/assets/icon_9.png" />
                  <router-link to="/product/customer-leads">
                    商机挖掘
                  </router-link>
                </a-menu-item>
                <a-menu-item key="customer-recommend"
                  ><img src="~@/assets/icon_10.png" />
                  <router-link to="/product/customer-recommend">
                    客户推荐
                  </router-link>
                </a-menu-item>
              </a-menu-item-group>
              <a-menu-item-group title="" class="product-nav">
                <a-menu-item key="sub-im" class="khxp">
                  <router-link to="/product/im"> 综合服务 </router-link>
                </a-menu-item>
                <a-menu-item key="scrm"
                  ><img src="~@/assets/icon_客户管理.png" />
                  <router-link to="/product/scrm"> 客户管理 </router-link>
                </a-menu-item>
                <!-- <a-menu-item key="intelligent-marketing"
                  ><img src="~@/assets/icon_智能营销.png" />
                  <router-link to="/product/intelligent-marketing">
                    智能营销
                  </router-link>
                </a-menu-item> -->
                <a-menu-item key="mail"
                  ><img src="~@/assets/icon_营销邮件.png" />
                  <router-link to="/product/mail"> 营销邮件 </router-link>
                </a-menu-item>
                <a-menu-item key="global"
                  ><img src="~@/assets/icon_全球电话.png" />
                  <router-link to="/product/global"> 全球电话 </router-link>
                </a-menu-item>
                <a-menu-item key="im"
                  ><img src="~@/assets/icon_即时通讯.png" />
                  <router-link to="/product/im"> 即时通讯 </router-link>
                </a-menu-item>
              </a-menu-item-group>
            </a-sub-menu>
            <a-menu-item key="productPrice">
              <router-link to="/productPrice">
                价格
              </router-link>
            </a-menu-item>
            <!-- <a-menu-item key="solution">
              <router-link to="/customer"> 解决方案 </router-link>
            </a-menu-item>
            <a-menu-item key="customer">
              <router-link to="/customer"> 客户案例 </router-link>
            </a-menu-item> -->
            <a-menu-item key="school">
              <router-link to="/school"> 外贸学院 </router-link>
            </a-menu-item>

            <a-sub-menu key="resource">
              <template #icon>
                <setting-outlined />
              </template>
              <template #title
                >资源中心</template
              >

              <a-menu-item key="help"
                ><img src="~@/assets/icon_常见问题.png" />
                <router-link to="/resource/help">帮助中心</router-link>
              </a-menu-item>
              <a-menu-item key="navigation"
                ><img src="~@/assets/icon_资源导航.png" />
                <router-link to="/resource/navigation"> 资源导航 </router-link>
              </a-menu-item>
              <a-menu-item key="template">
                <img src="~@/assets/icon_网站模版.png" />
                <router-link to="/resource/template"> 网站模板 </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item key="cooperation">
              <router-link to="/cooperation"> 渠道合作 </router-link>
            </a-menu-item>
            <a-menu-item key="about">
              <router-link to="/about"> 关于我们 </router-link>
            </a-menu-item>
            <!-- <a-menu-item key="oldLine">
              <a href="/old" target="_blank" rel="noopener noreferrer"
                >切换旧版</a
              >
            </a-menu-item> -->
          </a-menu>
        </div>
        <div class="nav-right-btn">
          <a href="/old" target="_blank">
            <div class="old-btn">
              <a-icon type="swap" />
              <span>切换旧版</span>
            </div>
          </a>
          <a @click="showModal" target="_blank"
            ><div class="nav-login-btn">登录</div>
          </a>
          <a @click="showModal"><div class="nav-register-btn">免费注册</div></a>
        </div>
      </div>
    </div>

    <div class="nav_mob">
      <router-link to="/">
        <img
          class="nav-logo"
          referrerpolicy="no-referrer"
          src="~@/assets/logo.png"
      /></router-link>

      <a-button @click="showDrawer">
        <a-icon type="unordered-list" />
        <!-- <i class="iconfont icon-menu"></i> -->
      </a-button>
      <a-drawer
        placement="right"
        :closable="false"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
      >
        <a-menu
          v-model="current"
          :default-open-keys="['product', 'resource']"
          mode="inline"
        >
          <a-menu-item key="home">
            <router-link to="/" rel="noopener noreferrer"> 首页 </router-link>
          </a-menu-item>
          <a-menu-item key="productService">
            <router-link to="/productService" rel="noopener noreferrer">
              产品服务
            </router-link>
          </a-menu-item>
          <a-menu-item key="productPrice">
            <router-link to="/productPrice" rel="noopener noreferrer">
              价格
            </router-link>
          </a-menu-item>
          <a-sub-menu key="product" v-if="false">
            <span slot="title">产品服务</span>
            <!-- <template
              >产品服务</template
            > -->
            <a-menu-item key="igeek">
              <router-link to="/product/igeek" rel="noopener noreferrer">
                外贸极客
              </router-link>
            </a-menu-item>
            <!--<a-menu-item key="iexpress">-->
            <!--<router-link to="/product/iexpress" rel="noopener noreferrer">-->
            <!--询盘速递-->
            <!--</router-link>-->
            <!--</a-menu-item>-->
            <!--<a-menu-item key="iserver">-->
            <!--<router-link to="/product/iserver" rel="noopener noreferrer">-->
            <!--询盘服务-->
            <!--</router-link>-->
            <!--</a-menu-item>-->
            <!--<a-menu-item key="brand">-->
            <!--<router-link to="/product/brand" rel="noopener noreferrer">-->
            <!--品牌营销-->
            <!--</router-link>-->
            <!--</a-menu-item>-->
            <a-menu-item key="imarket">
              <router-link to="/product/imarket" rel="noopener noreferrer">
                询盘市场
              </router-link>
            </a-menu-item>
            <!--<a-menu-item key="customer-inquiry">-->
            <!--<router-link to="/product/customer-inquiry"> 询盘监控 </router-link>-->
            <!--</a-menu-item>-->
            <a-menu-item key="customs">
              <router-link to="/product/customs" rel="noopener noreferrer">
                海关数据
              </router-link>
            </a-menu-item>
            <a-menu-item key="intelligent-search">
              <router-link to="/product/intelligent-search">
                客户智搜
              </router-link>
            </a-menu-item>
            <a-menu-item key="search-customer">
              <router-link to="/product/search-customer">
                搜邮寻客
              </router-link>
            </a-menu-item>
            <a-menu-item key="customer-leads">
              <router-link to="/product/customer-leads"> 商机挖掘 </router-link>
            </a-menu-item>
            <a-menu-item key="customer-recommend">
              <router-link to="/product/customer-recommend">
                客户推荐
              </router-link>
            </a-menu-item>
            <a-menu-item key="scrm">
              <router-link to="/product/scrm"> 客户管理 </router-link>
            </a-menu-item>
            <a-menu-item key="intelligent-marketing">
              <router-link to="/product/intelligent-marketing">
                智能营销
              </router-link>
            </a-menu-item>
            <a-menu-item key="mail">
              <router-link to="/product/mail"> 营销邮件 </router-link>
            </a-menu-item>
            <a-menu-item key="global">
              <router-link to="/product/global"> 全球电话 </router-link>
            </a-menu-item>
            <a-menu-item key="im">
              <router-link to="/product/im"> 即时通讯 </router-link>
            </a-menu-item>
          </a-sub-menu>

          <!-- <a-menu-item key="customer" disabled>
            <router-link to="/customer" rel="noopener noreferrer">
              客户案例
            </router-link>
          </a-menu-item>

          <a-menu-item key="school" disabled>
            <router-link to="/school" rel="noopener noreferrer">
              外贸学院
            </router-link>
          </a-menu-item> -->

          <!-- <a-menu-item key="resource" class="ab-mob-product"> -->
          <a-sub-menu key="resource">
            <span slot="title">资源中心</span>
            <a-menu-item key="help">
              <router-link to="/resource/help">帮助中心</router-link>
            </a-menu-item>
            <a-menu-item key="navigation">
              <router-link to="/resource/navigation"> 资源导航 </router-link>
            </a-menu-item>
            <a-menu-item key="template">
              <router-link to="/resource/template"> 网站模板 </router-link>
            </a-menu-item>
          </a-sub-menu>

          <a-menu-item key="cooperation">
            <router-link to="/cooperation" rel="noopener noreferrer">
              渠道合作
            </router-link>
          </a-menu-item>

          <a-menu-item key="about">
            <router-link to="/about" rel="noopener noreferrer">
              关于我们
            </router-link>
          </a-menu-item>
        </a-menu>
      </a-drawer>
    </div>
    <AIAssitant ref="aIAssitant" />
    <inquiry-modal-vue
      :visible="visible1"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
  </a-affix>
</template>

<script>
// import Affix from "ant-design-vue/es/affix";
// import Menu from "ant-design-vue/es/menu";
// import { Affix, Menu, rawer } from "ant-design-vue";
import AIAssitant from "./aiAssitant/index.vue";
import inquiryModalVue from "@/components/InquiryModal2.vue";
export default {
  components: {
    AIAssitant,
    inquiryModalVue,
  },
  name: "MainNav",
  data() {
    return {
      top: 0,
      current: ["home"],
      visible: false,
      visible1: false,
    };
  },
  methods: {
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    openAIModal() {
      this.$refs.aIAssitant.openModal();
    },
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible1 = true;
    },

    handleCancel() {
      this.visible1 = false;
    },
  },
  mounted() {
    if (this.$route.path) {
      let pathList = this.$route.path.split("/");
      if (!pathList[2] === false) {
        this.current = [pathList[2]];
      } else if (!pathList[1] === false) {
        this.current = [pathList[1]];
      }
    }
  },
};
</script>

<style lang="sass">
.mainnav {
  .ctaArea {
    height: 40px;
background: #013C96;
font-size: 14px;
color: #FFFFFF;
line-height:40px;
.ctaLabelArea {
  font-family: "Alibaba-PuHuiTi-R";
  // width: 1700px;
  display: flex;
justify-content: space-between;
margin: 0 auto;
font-weight: 400;

a {
  color: #fff;
  text-decoration: underline;
}
  .iconfont {
    cursor: pointer;
    margin-left: 8px;
    }
}
  }
}
.nav .nav-bar li {
    height: 1.65333rem;
}
.ant-drawer-content{
  li{
    padding:10px 0px;
  }
}
.ab-mob-product{
  color:#999;
  li{
    padding-left:20px;
  }
}
/* 菜单 */
.product-nav.ant-menu-item-group{
  width: 200px;
  img {
    width: 30px;
  }
}
.ant-btn .icon-menu{
  font-size:60px
}
.ant-menu-item-group-list{
  position: relative
}
.ant-menu-vertical .khxp.ant-menu-item{
  position:absolute;
  top:-60px;
  margin-left:5px;
  a{
    &:hover{
      color: #1890ff;
    }
    color:#333;
    font-size:18px;
  }
}
.ant-menu-item-group-title {
  font-size: 18px;
  height:40px;
  color: #333;
  padding-left: 0px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}
.ant-menu-vertical.ant-menu-sub {

  padding: 10px 25px 20px 25px;
  margin-left: -51px;    min-width: 200px;
}

.ant-menu-item-group {

  float: left;
}
.ant-menu-item-group-list .ant-menu-item {
  padding: 0px;
  font-size: 16px;

}
.ant-menu-vertical .ant-menu-item a{
  font-size:16px
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background:#fff;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item img {
  margin-right: 12px;
  float: left;
  position: relative;
  top: 6px;
}
.ant-menu-item-group-list .ant-menu-item:hover {
  color: #1a88fe;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 6px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 6px solid #1890ff;
}
.ant-affix .ant-menu-item,
.ant-affix .ant-menu-submenu-title {
  height: 56px;
}
.justify-between .ant-carousel .slick-dots-bottom {
  bottom: 50px;
  // left: 126px;
  left: calc(50% - 21.6rem + 2.66667rem);
}
@media screen and (min-width: 1400px) and (max-width: 1800px) {
  .justify-between .ant-carousel .slick-dots-bottom {
  left: calc(50% - 22.75rem + 2.66667rem);
}
  }
.nav {
   font-family: AlibabaPuHuiTiM;
   width:1620px;
  margin:0px auto;
  transform: translateZ(0);
  transition: all .3s;

  .nav-bar{
    li{
        font-size:18px;
        color:#333;
        margin: 0px 20px;
       .ddd{
        font-size:18px;
       }

    }
   ul{
    background:none;
    margin-top: 20px;
        transform: translateZ(0);
    transition: all .3s;
   }
      a{
        font-size:18px;
        color:#333
       }

  }

  .nav-logo,.nav-logo-white {
    width: 60px;
    height: 50px;
    float: left;
    margin-top:30px;
    margin-right:20px;
    transform: translateZ(0);
    transition: all .3s;
  }
  .nav-logo-white {
    transform: scale(1.4);
  }
.nav-right-btn{
  font-family: "Alibaba-PuHuiTi-R";
  float: right;
  margin-top: 26px;
  display: flex;
    align-items: center;
    column-gap: 10px;

      transform: translateZ(0);
    transition: all .3s;
}
  .nav-name{

    height: 25px;
    font-size: 18px;

    color: #333646;
    line-height: 25px;
    margin-right: 80px;
  }

.old-btn {
  font-size: 14px;
color: #666666;
display: flex;
column-gap: 2px;
align-items: center;
.anticon {
  width: 16px;
height: 16px;
border: 1px solid #CACDD7;
border-radius:50%;
  padding: 2px;
  font-size: 10px;

  }
  }
  .nav-login-btn {
    border: 1px solid #0680F2;
    width: 104px;
    height:34px;
    line-height:34px;
    border-radius: 3px;
    text-align:center;
    font-size:14px;
    color: #0680F2;
    cursor: pointer;
    float: left;
    // margin-right:27px;
    a {
      color: #0680F2;
    }
  }

  .nav-register-btn {
    width: 104px;
height: 34px;
background: #0680F2;
     float: left;
  line-height:34px;
    border-radius: 3px;
    color:#fff;
    cursor: pointer;
    font-size:14px;
    text-align:center;
    a {
      color: #fff;

    }
  }
  .ant-menu-horizontal{
    border:none
  }
  .ant-menu{
    float: left
  }

}
.ant-affix {

.nav{
  background:#fff;
  width: auto;
  height:80px;
  transition: all .3s;
    box-shadow: 8px 8px 20px 0 rgb(55 99 170 / 10%);
     .nav-right-btn{
      margin-top:0.54333rem;
     }
    .nav_top{
        width:1620px;
          margin:0px auto;
    }
    .nav-logo, .nav-logo-white {
      margin:0px;
      width: 50px;
      height: auto;
      margin-right: 46px;
      margin-top: 18px;
    }
    .nav-logo-white {
      transform: scale(1);
    }
    .nav-bar {


      ul{
        margin-top:17px;
      }
      li{
        height:1.65333rem
      }
    }
}
}
</style>
