<template>
  <!-- 弹出框 -->
  <div>
    <a-modal
      title=""
      :confirm-loading="confirmLoading"
      footer=" "
      @cancel="handleCancel"
      width="900px"
    >
      <div class="ad-model inquirymodal_mob">
        <a-row>
          <a-col :xl="11" :xs="11">
            <img src="~@/assets/product/弹出框1.png" alt="" />
            <div class="ad-model-left"></div>
          </a-col>
          <a-col :xl="13" :xs="13">
            <div class="ad-model-right">
              <div class="fontsize32">
                {{ isInquiry ? "让跨境营销更简单" : "" }}
              </div>
              <a-form
                v-if="isInquiry"
                :form="form"
                :wrapper-col="{ span: 24 }"
                @submit="handleSubmit"
              >
                <!-- <a-form-item label="">
                  <a-input
                    placeholder="请输入您的公司名称"
                    v-decorator="['name']"
                  />
                </a-form-item> -->

                <a-form-item label="">
                  <a-input
                    :max-length="11"
                    placeholder="*请输入您的手机号码"
                    v-decorator="[
                      'phone',
                      {
                        rules: [
                          { required: true, message: '请输入您的手机号码' },
                          {
                            pattern: /^1[3456789]\d{9}$/,
                            message: '手机号码格式错误',
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item label="">
                  <a-input
                    :max-length="6"
                    placeholder="*请输入验证码"
                    v-decorator="rules"
                  >
                    <template #suffix>
                      <div
                        :disabled="disabled"
                        @click="getCode"
                        :style="{ cursor: disabled ? 'no-drop' : 'default' }"
                      >
                        {{ btnTxt }}
                      </div>
                    </template>
                  </a-input>
                </a-form-item>
                <a-form-item label="">
                  <a-checkbox
                    :max-length="6"
                    placeholder="*请勾选协议"
                    v-decorator="[
                      'checkPolicy',
                      {
                        rules: [{ required: true, message: '请勾选协议' }],
                      },
                    ]"
                  >
                    <span>我已阅读并同意</span
                    ><a
                      style="color: #1890ff;"
                      target="_blank"
                      href="https://www.cnabke.com/terms_of_service.html?tdsourcetag=s_pctim_aiomsg"
                      >《AB客用户协议》</a
                    >&nbsp;和&nbsp;<a
                      style="color: #1890ff;"
                      target="_blank"
                      href="http://shmuker.oss-cn-hangzhou.aliyuncs.com/app/doc/privacy_policy.html"
                      >《隐私协议》</a
                    >
                  </a-checkbox>
                </a-form-item>

                <a-form-item>
                  <a-button
                    type="primary"
                    html-type="submit"
                    class="fontsize18"
                  >
                    立即注册
                  </a-button>
                </a-form-item>
              </a-form>
              <div v-else class="right2">
                <div class="right2_title">
                  <span class="iconfont icon-duihao2"></span>
                  <div>注册成功</div>
                </div>
                <div class="right2_btn">
                  <div class="look" @click="handleCancel">
                    继续浏览
                  </div>
                  <a
                    style="flex:1"
                    href="https://crm.cnabke.com/#/passport/register"
                    data-source="p"
                    onmouseover="linkDataSource(this)"
                    a-type="register"
                    target="_blank"
                    ><div class="banner_btn_item banner_btn_item_primary">
                      立即使用
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>

  <!-- 弹出框 -->
</template>

<script>
import { submitClues } from "@/api/inquiry";
import { getLoginCode, doLoginByCode } from "@/api/app-crm.js";
import { message } from "ant-design-vue";
import axios from "axios";
export default {
  props: ["visible"],

  data() {
    return {
      // modalVisible: false,
      btnTxt: "获取验证码",
      confirmLoading: false,
      disabled: false,
      form: this.$form.createForm(this, {
        name: "dynamic_rule",
      }),
      rules: ["code"],
      isInquiry: true,
      checkPolicy: true,
    };
  },

  mounted() {},
  methods: {
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btnTxt = this.time + "s后重新获取验证码";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnTxt = "获取验证码";
        this.disabled = false;
      }
    },

    // 获取验证码
    getCode() {
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.time = 60;
        this.timer();
        this.disabled = true;
        this.getLoginCode(values);
      });
    },

    async getLoginCode(e) {
      const params = {
        mobile: e.phone,
        // isRegister: 0,
      };

      const res = await getLoginCode(params);
      if (res["code"] === 200) {
        this.time = 60;
        this.timer();
        this.disabled = true;
        message.success("发送成功，请注意查看手机短信！");
      } else {
        message.error(res["errMsg"]);
      }
    },

    handleCancel(e) {
      this.$emit("cancel");
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        console.log("Received values of form: ", values);

        let params = {
          name: values["name"],
          code: values["code"],
          isRegister: 0,
          source: 1,
          userName: values.phone,
        };

        doLoginByCode(params).then((res) => {
          if (res.success) {
            message.success("注册成功！");
            this.isInquiry = false;
          }
          // 重置表单状态
          this.form.resetFields();

          // this.$emit("cancel");
        });
      });

      //
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .has-error .ant-form-explain{
    position: absolute;
    margin-top: 0px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: -2px;
  background: #f7f7f8;
  font-size: 16px;
  border-radius: 8px;
  text-indent: 10px;
  border-color: #fff;
  width: 100%;
  height: 48px;
}

.banner_btn_item {
  width: 223px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #0076FF;
  font-weight: 500;
  font-family: AlibabaPuHuiTiM;
  font-size: 18px;
  cursor: pointer;
  color: #0076FF;

  }
.ad-model{
  .ad-model-right{
    .inquiry_checkbox{
      padding:0px 20px;
      .ant-checkbox-wrapper{
        font-size:16px;
      }
    }
    .ant-form-item{
      margin-bottom:20px
    }
    .fontsize32{
      text-align:center;
      padding-bottom:20px;
      width: 100%;
      text-align:center;
      color:#333;
      font-weight:bolder;
    }
    .right2{
      .right2_title {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        justify-content: center;
        align-items: center;
        .iconfont {
          display: inline-block;
          font-size: 100px;
          color: #1BB84F;
          }
            div {
              text-align: center;
              font-size: 20px;
              }
        }
      .right2_btn {
        display: flex;
        column-gap: 20px;
        }
      .banner_btn_item{
        width: 100%;
        background: #0076FF;
        color: #fff;
      }
      .look{
        width: 49%;
        height: 55px;
        border-radius: 7px;
        border: 0.02667rem solid #0076FF;
        font-weight: 500;
        font-family: AlibabaPuHuiTiM;
        line-height: 55px;
        text-align: center;
        font-size: 18px;
        margin-bottom:20px;
        cursor: pointer;
        color: #0076FF;
      }

    }

   button{
      &:hover{
        background: linear-gradient(230deg, #3d97f3 0%, #095bd1 100%);
      }
      height: 48px;
      background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%);
      border-radius: 7px;width: 100%;
    }
    .ant-checkbox-group label.ant-checkbox-wrapper{
        color:#333;font-size:18px;
    }
    .ant-checkbox-group .ant-row{padding:0px 11px}
    .fontsize16{text-align: left;    padding-left: 12px;
    font-size:16px !important;
      color:rgba(51,51,51,0.4);padding-top:0px;
    }
    .has-error .ant-input{
          border-color: #f5222d;
    }
    input{
      height: 48px;
      background: #f7f7f8;
      font-size:16px;
      border-radius: 8px;
      text-indent:10px;
      border-color:#fff;
      width:100%
    }
    padding:30px 60px 0px 60px;

  }
  .ant-col-xl-11{
    img{
   max-height:100%

    }
    height: 556px;
    text-align:center;
    position: relative;
    background: #1d81fc
  }
}

::v-deep .ant-modal-body{
  padding:0px;
}
::v-deep .ant-modal-close-icon{
  &:hover{
    opacity: 0.4;
  }
  width: 25px;
  height: 25px;
  background: #999;
  opacity: 0.2;
  border-radius: 16px;font-size: 15px;
  padding-top: 5px;
  color:#fff;

}
::v-deep .ant-modal-content{
  box-shadow: 0px 10px 20px 0px rgba(0,8,29,0.3000);
border-radius: 16px;
overflow: hidden
}
::v-deep .ant-modal-footer{
  display: none;
}
::v-deep .ant-modal-mask {
    background-color: rgba(255, 255,255, 0.3);
    filter: alpha(opacity=20);
}
</style>
