import request from "@/utils/request";

export function aiRoleDetail(params) {
  return request({
    url: "/app-ims/ai/webCustomerService/create",
    method: "post",
    data: params,
  });
}
export function getQuestionList(params) {
  return request({
    url: "/app-ims/ai/webCustomerService/getQuestionList",
    method: "post",
    data: params,
  });
}
export function getResourceList(params) {
  return request({
    url: "/app-ims/ai/webCustomerService/getResourceList",
    method: "post",
    data: params,
  });
}

export function findById(params) {
  return request({
    url: "/app-ims/ai/webCustomerService/getChatLog",
    method: "post",
    data: params,
  });
}

export function chatLog(params) {
  return request({
    url: "/app-ims/ai/webCustomerService/getChatLogList",
    method: "post",
    data: params,
  });
}
export function addQuestionTimes(params) {
  return request({
    url: "/app-ims/ai/webCustomerService/addQuestionTimes",
    method: "post",
    data: params,
  });
}
export function askQuestion(params) {
  return request({
    url: "/app-ims/ai/webCustomerService/askQuestion",
    method: "post",
    data: params,
  });
}
