<template>
  <div class="chatContainer">
    <div class="chatContent">
      <Chatlog
        ref="chatlog"
        @change="changeInput"
        :prologue="prologue"
        :logo="logo"
        :secret="secret"
      />
      <ChatInput
        ref="chatInput"
        @send="sendLog"
        @changeLanguage="changeLanguage"
      />
    </div>
  </div>
</template>
<script>
import Chatlog from "./chatlog.vue";
import ChatInput from "./chatInput.vue";

export default {
  components: {
    Chatlog,
    ChatInput,
  },
  props: {
    prologue: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    secret: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      faqList: [],
      sessionRole: {},
      businessId: "",
      params: {},
    };
  },
  methods: {
    changeLanguage(value) {
      this.$refs.chatlog.changeLanguage(value);
    },
    pushData(data) {
      this.$refs.chatlog.pushLog(data);
    },
    changeInput(text) {
      this.changeText(text);
    },
    changeText(value) {
      this.$refs.chatInput.setSendText(value);
    },
    sendLog(data) {
      this.$refs.chatlog.sendLog(data);
    },
    setParams(data) {
      this.$refs.chatlog.setParams(data);
    },
  },
};
</script>

<style lang="sass">
@import url(./index.less);
</style>
