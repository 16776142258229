<template>
  <a-drawer
    width="80%"
    :footer="false"
    title="智能客服"
    placement="right"
    :visible="visible"
    @close="onClose"
    style="z-index: 1000000"
    class="chatDrawer"
  >
    <div class="chat-box" v-if="visible">
      <div class="left">
        <div class="robotDescArea" style="height: 40px;">
          <!-- <img class="hiPng" :src="hiPng" /> -->
          <img class="robotPng" :src="roleInfo.logo || robotPng" />
          <div class="title">{{ roleInfo.duty }}</div>
          <!-- <div class="robotDesc commonScroll">
            {{ roleInfo.duty }}
          </div> -->
        </div>
        <a-tabs class="mt20">
          <a-tab-pane key="1">
            <span slot="tab">
              <span class="iconfont icon-wenhao"></span>
              常见问题
            </span>
            <div
              class="faqList commonScroll"
              style="height: calc(100vh - 213px);"
            >
              <div
                class="faqItem"
                @click="chooseFaq(item)"
                v-for="(item, index) in faqList"
                :key="item"
              >
                <div
                  class="cricle"
                  :style="{ background: index > 2 ? '#FCAB09' : '#FC4408' }"
                ></div>
                <div class="lineClamp">
                  {{
                    index + 1 + (pagination.current - 1) * pagination.pageSize
                  }}. {{ item.question }}
                </div>
              </div>
              <a-pagination
                v-if="pagination.total > pagination.pageSize"
                v-model="pagination.current"
                :total="pagination.total"
                :pageSize="pagination.pageSize"
                @change="changePage"
                show-less-items
              />
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <span class="iconfont icon-xinwenzixun"></span>
              相关资料
            </span>
            <div class="commonScroll" style="height: calc(100vh - 213px);">
              <File ref="fileList" :fileList="fileList" />
              <a-pagination
                v-if="filePagination.total > filePagination.pageSize"
                v-model="filePagination.current"
                :total="filePagination.total"
                :pageSize="filePagination.pageSize"
                @change="changeFilePage"
                show-less-items
              />
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="right">
        <AIChat
          :chatType="'AIChatMarket'"
          ref="aiChat"
          :prologue="roleInfo.prologue"
          :logo="roleInfo.logo || robotPng"
          :secret="secret"
        ></AIChat>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import AIChat from "./aiChat.vue";
import File from "./fileList.vue";
import hiPng from "@/assets/home/hi.png";
import robotPng from "@/assets/home/aitx.png";
import {
  aiRoleDetail,
  getQuestionList,
  getResourceList,
  addQuestionTimes,
  askQuestion,
} from "@/api/chat";
import moment from "moment";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import CryptoJS from "crypto-js";

export default {
  components: {
    AIChat,
    File,
  },
  props: {
    type: {
      type: String,
      default: "AIChat",
    },
  },
  data() {
    return {
      hiPng: hiPng,
      robotPng: robotPng,
      pagination: {
        current: 1,
        pageSize: 20,
      },
      visible: false,
      activeTag: "产品相关",
      roleInfo: {},
      inquiryPackageId: localStorage.getItem("inquiryPackageId"),
      faqList: [],
      fileList: [],
      filePagination: {
        current: 1,
        pageSize: 20,
      },
      sessionRole: {},
      businessId: "",
      commonParams: {
        requesterId: "",
        dataTemplateId: "",
        timestamp: "",
        sign: "",
      },
      secret: "",
    };
  },
  methods: {
    dateTime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    async setParams() {
      if (process.env.NODE_ENV !== "development") {
        if (
          window.location.hostname.indexOf("dev-") !== -1 ||
          window.location.hostname.indexOf("test-") !== -1
        ) {
          //  dev环境
          this.commonParams.dataTemplateId = "65c08cd9bd3e1053ee1b07e3";
        } else {
          //  正式环境
          this.commonParams.dataTemplateId = "6598b9fc78c75a7f6c1df2cb";
        }
      } else {
        this.commonParams.dataTemplateId = "65c08cd9bd3e1053ee1b07e3";
      }
      this.commonParams.timestamp = moment().valueOf();
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      this.commonParams.requesterId = result.visitorId;
      this.commonParams.sign = CryptoJS.MD5(
        this.commonParams.dataTemplateId +
          this.commonParams.requesterId +
          this.commonParams.timestamp +
          this.secret
      )
        .toString()
        .toUpperCase();
      console.log(this.commonParams.sign, "this.commonParams.sign");
    },
    onClose() {
      this.visible = false;
    },
    async getFileList() {
      await this.setParams();
      const query = {
        pages: {
          pageNum: this.filePagination.current,
          pageSize: this.filePagination.pageSize,
        },
        ...this.commonParams,
      };
      const res = await getResourceList(query);
      if (res.code === 200) {
        this.fileList = res.data.list || [];
        this.filePagination.total = res.data.pages.totalCount;
        this.filePagination.pageSize = res.data.pages.pageSize;
        this.fileList.forEach((item) => {
          item.fileInfoDto = item.fileInfoDto || {};
          item.url = item.fileInfoDto.url;
        });
      }
    },
    async getFaqList() {
      await this.setParams();
      const query = {
        pages: {
          pageNum: this.pagination.current,
          pageSize: this.pagination.pageSize,
        },
        ...this.commonParams,
      };
      const res = await getQuestionList(query);
      if (res.code === 200) {
        this.faqList = res.data.list || [];
        this.pagination.total = res.data.pages.totalCount;
        this.pagination.pageSize = res.data.pages.pageSize;
        this.faqList.forEach((item) => {
          item.text = item.question;
          item.result = item.answer;
          item.textBr = item.text.replace(/\n/g, "<br>");
          item.resultBr = item.result;
        });
      }
    },
    changePage(page) {
      this.pagination.current = page;
      this.getFaqList();
    },
    changeFilePage(page) {
      this.filePagination.current = page;
      this.getFileList();
    },
    async chooseFaq(data) {
      await this.setParams();
      data.time = Date.now();
      data.timeStr = this.dateTime(data.time);
      this.$refs.aiChat.pushData(data);
      const res = await askQuestion({
        ...this.commonParams,
        id: data.id,
      });
    },
    async getDefaultRole() {
      const res = await aiRoleDetail(this.commonParams);
      if (res.code === 200) {
        this.roleInfo = res.data || {};
        if (this.roleInfo.id) {
          this.visible = true;
          this.secret = res.data.secret;
          await this.setParams();
          this.getFaqList();
          this.getFileList();
          this.$refs.aiChat.setParams(this.commonParams);
        }
      }
    },
    async openModal() {
      // this.visible = true;
      this.secret = "";
      await this.setParams();
      this.getDefaultRole();
    },
  },
};
</script>

<style lang="sass">
.chatDrawer {
  .ant-drawer-wrapper-body {
    overflow: hidden;}
  .ant-drawer-body {
  padding: 10px !important;
  li {
    padding: 0px;}
   }
.robotDescArea {
  position: relative;
  background: url("~@/assets/home/bg1.png") 100% no-repeat;
  background-size: cover;
  // min-height: 235px;
  // height: 40px;
  border-radius: 3px;
  padding: 67px 13px 13px 13px;
  // margin-top: 16px;
  flex: 1;
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    top: 27px;
    left: 97px;
  }
  .robotSet {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .hiPng {
    position: absolute;
    width: 17px;
    top: -73px;
    left: 50px;
  }
  .robotPng {
    position: absolute;
    top: 0px;
    left: 14px;
    width: 71px;
    object-fit: cover;
    z-index: 10;
  }
  .robotDesc {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #ffffff;
    width: 100%;
    height: 195px;
    position: relative;
    z-index: 9;
    border-radius: 5px;
    color: #4e5969;
    line-height: 28px;
    padding: 15px 10px;
    backdrop-filter: blur(2px);
    // font-size: 14px;
    span {
      font-weight: 500;
      color: #000000;
    }
  }
}
.chat-box {
  display: flex;
  width: 100%;
  height: 100%;
  column-gap: 10px;
  .left {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .arco-tabs-nav {
      justify-content: center;
    }
     .arco-tabs-nav-tab {
      flex: none;
    }

    .refresh {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .tags {
        width: 68px;
        height: 24px;
        background: #f7f7f9;
        border-radius: 6px 6px 6px 6px;
        line-height: 24px;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        cursor: pointer;
      }
      .activeTag {
        background: rgba(22, 93, 255, 0.1);
        color: rgba(22, 93, 255, 0.8);
      }
    }
    .faqList {
      // margin-top: 10px;
      // height: calc(100vh - 380px);
      overflow-y: auto;
      padding: 0 15px 0 0;
      .faqItem:hover {
        background: rgba(22,93,255,0.05);
        .lineClamp {
          color: rgba(22,93,255,0.8);
          }
        }
      .faqItem {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        padding: 10px 0;
        .cricle {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .lineClamp {
          // line-height: 16px;
          // -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // word-wrap: break-word;
          // font-size: 12px;
          color: #000;
          font-weight: 500;
          width: 96%;
        }
      }
    }
  }
  .right {
    // flex: 1;
    // display: flex;
    // justify-content: space-between;
    // flex-direction: column;
    // padding-bottom: 10px;
    width: 70%;
    height: 100%;
    padding: 0 10px;
    background-color: #f6f7fb;
  }
}
  }
</style>
