var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"","confirm-loading":_vm.confirmLoading,"footer":" ","width":"900px"},on:{"cancel":_vm.handleCancel}},[_c('div',{staticClass:"ad-model inquirymodal_mob"},[_c('a-row',[_c('a-col',{attrs:{"xl":11,"xs":11}},[_c('img',{attrs:{"src":require("@/assets/product/弹出框1.png"),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"ad-model-left"})]),_vm._v(" "),_c('a-col',{attrs:{"xl":13,"xs":13}},[_c('div',{staticClass:"ad-model-right"},[_c('div',{staticClass:"fontsize32"},[_vm._v("\n              "+_vm._s(_vm.isInquiry ? "让跨境营销更简单" : "")+"\n            ")]),_vm._v(" "),(_vm.isInquiry)?_c('a-form',{attrs:{"form":_vm.form,"wrapper-col":{ span: 24 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phone',
                    {
                      rules: [
                        { required: true, message: '请输入您的手机号码' },
                        {
                          pattern: /^1[3456789]\d{9}$/,
                          message: '手机号码格式错误',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'phone',\n                    {\n                      rules: [\n                        { required: true, message: '请输入您的手机号码' },\n                        {\n                          pattern: /^1[3456789]\\d{9}$/,\n                          message: '手机号码格式错误',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"max-length":11,"placeholder":"*请输入您的手机号码"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.rules),expression:"rules"}],attrs:{"max-length":6,"placeholder":"*请输入验证码"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('div',{style:({ cursor: _vm.disabled ? 'no-drop' : 'default' }),attrs:{"disabled":_vm.disabled},on:{"click":_vm.getCode}},[_vm._v("\n                      "+_vm._s(_vm.btnTxt)+"\n                    ")])]},proxy:true}],null,false,1374539252)})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":""}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'checkPolicy',
                    {
                      rules: [{ required: true, message: '请勾选协议' }],
                    },
                  ]),expression:"[\n                    'checkPolicy',\n                    {\n                      rules: [{ required: true, message: '请勾选协议' }],\n                    },\n                  ]"}],attrs:{"max-length":6,"placeholder":"*请勾选协议"}},[_c('span',[_vm._v("我已阅读并同意")]),_c('a',{staticStyle:{"color":"#1890ff"},attrs:{"target":"_blank","href":"https://www.cnabke.com/terms_of_service.html?tdsourcetag=s_pctim_aiomsg"}},[_vm._v("《AB客用户协议》")]),_vm._v(" 和 "),_c('a',{staticStyle:{"color":"#1890ff"},attrs:{"target":"_blank","href":"http://shmuker.oss-cn-hangzhou.aliyuncs.com/app/doc/privacy_policy.html"}},[_vm._v("《隐私协议》")])])],1),_vm._v(" "),_c('a-form-item',[_c('a-button',{staticClass:"fontsize18",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("\n                  立即注册\n                ")])],1)],1):_c('div',{staticClass:"right2"},[_c('div',{staticClass:"right2_title"},[_c('span',{staticClass:"iconfont icon-duihao2"}),_vm._v(" "),_c('div',[_vm._v("注册成功")])]),_vm._v(" "),_c('div',{staticClass:"right2_btn"},[_c('div',{staticClass:"look",on:{"click":_vm.handleCancel}},[_vm._v("\n                  继续浏览\n                ")]),_vm._v(" "),_c('a',{staticStyle:{"flex":"1"},attrs:{"href":"https://crm.cnabke.com/#/passport/register","data-source":"p","onmouseover":"linkDataSource(this)","a-type":"register","target":"_blank"}},[_c('div',{staticClass:"banner_btn_item banner_btn_item_primary"},[_vm._v("\n                    立即使用\n                  ")])])])])],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }