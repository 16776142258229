<template>
  <div class="chatFileContainer">
    <div class="fileItem" v-for="item in fileList" @click="openUrl(item.url)">
      <img
        :src="`${item.url}?x-oss-process=image/resize,m_lfit,w_200`"
        v-if="item.contentType === 'IMG'"
      />
      <div class="otherImg" v-else>
        <img
          src="../../assets/file/doc.png"
          alt=""
          v-if="item.contentType === 'WORD'"
        />
        <img
          src="../../assets/file/ppt.png"
          alt=""
          v-if="item.contentType === 'PPT'"
        />
        <img
          src="../../assets/file/xls.png"
          alt=""
          v-if="item.contentType === 'EXCEL'"
        />
        <img
          src="../../assets/file/pdf.png"
          alt=""
          v-if="item.contentType === 'PDF'"
        />
        <img
          src="../../assets/file/zip.png"
          alt=""
          v-if="item.contentType === 'COMPRESSED'"
        />
        <img
          src="../../assets/file/unknown.png"
          alt=""
          v-if="item.contentType === 'OTHER'"
        />
        <a-icon
          type="play-circle"
          class="icon-play"
          v-if="item.contentType === 'VIDEO'"
        />
        <img
          v-if="item.contentType === 'VIDEO'"
          :src="
            `${item.url}?x-oss-process=video/snapshot,t_100,f_jpg,w_200,m_fast`
          "
        />
        <!-- <video
        v-if="fileData.contentType === 'VIDEO' && showVideo"
        :src="fileData.url"
        controls
      >
      </video> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {},
};
</script>

<style lang="sass">
.chatFileContainer {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  .fileItem {
    width: 100px;
    height: 100px;
    border: 1px solid rgb(229,230,235);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: contain;
      }
    }
      .otherImg {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100px;
        .icon-play {
          position: absolute;
    color: #fff;
    font-size: 28px;}
        }
  }
</style>
