import request from "@/utils/request";

/**
 * 提交定制询盘信息
 * @param {*} params
 * @returns
 */
export function submitClues(params) {
  const data = {
    ...params,
    client: "WEBSITE",
  };
  return request({
    url: "/app-crm/cost/inquiryDemand/submitClues",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 询盘市场-》询盘信息列表
 * @param {*} params
 * @returns
 */
export function inquiryList(params) {
  let data = {
    ...params,
  };
  return request({
    url: "/app-crm/productService/webPublicList",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 行业列表
 * @param {*} params
 * @returns
 */
export function industryList(data) {
  return request({
    url: "/app-crm/productService/industry/search",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 类目别名列表
 * @param {*} params
 * @returns
 */
export function commodityList(data) {
  return request({
    url: "/app-crm/productService/commodity/search",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 询盘市场-》询盘信息详情
 * @param {*} params
 * @returns
 */
export function inquiryDetail(data) {
  return request({
    url: "/app-crm/productService/publicInquiryDetail",
    method: "post",
    data,
  });
}

/**
 * 询盘市场-》筛选联动
 * @param {*} params
 * @returns
 */
export async function categoryList(data = {}) {
  let resData = [];
  const list = await request({
    url: "/app-crm/productService/categoryList",
    method: "post",
    data,
  });
  console.log("categoryList=====", list);
  if (list.code === 200) {
    list.data.forEach((element) => {
      let children = [];
      element.categoryList.forEach((item) => {
        children.push({
          ...item,
          value: item.code,
          label: item.alias,
        });
      });
      let d = {
        value: element.industryCode,
        label: element.industryName,
        children,
      };
      resData.push(d);
    });
  }
  console.log("categoryList=====", resData);
  return resData;
}
